import React from "react";
import Layout from "../components/layout"
import { Link } from "gatsby"

export default () => (

  <Layout>
    <div className="flex items-center h-screen w-full ">
    <div className="text-green-700 bg-black rounded shadow-lg p-8 m-4  sm:w-full md:max-w-1/2 lg:max-w-1/4 xl:w-1/4 mx-auto  ">
    <h1  className="text-green-500 text-3xl leading-tight tracking-tighter">Thank you!</h1>
    <p className="text-base tracking-tight mb-6">You are an awesome caring person or robot. </p>

    <Link to="/" className="inline-flex items-center w-full">
        <svg className="stroke-current w-4 h-4 mr-2"xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
        <span className="py-4 text-green-500">home</span >
    </Link>

    </div>
    </div>
  </Layout>
);